import styled from "styled-components";

export const Form = styled.div`
    text-align: center;
    form{
        padding-bottom: 68px;
        grid-template-columns: 368px 76px 352px auto;    
        margin-top: 51px;
        display: grid;
        grid-gap: 16px;
        justify-content: center;
        position: relative;
        select{
            border-radius: 10px;
            padding: 18px 12px 18px 20px;
            width: 100%;
            margin: 0;
            font-family: Lato,sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: #707070;
            background-color: var(--bs-gray-200);
            -moz-appearance: none;
            text-indent: 0.01px;
            text-overflow: '';
        }

        button{
            border-radius: 10px;
            padding: 15px 20px;
            background: var(--btn-primary-txt);
            border: none;
            width: 76px;
            cursor: pointer;
            outline: none;
            @media (max-width: 48em) {
                width: 100%;
            }
            svg {
                fill: var(--btn-primary-bg);
            }
        }
    }
    @media (max-width: 768px){
        form{
            grid-template-columns: 1fr;
            padding-bottom: 39px;
        }
    }
`;