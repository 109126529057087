import axios from "axios";
import { API_LIST_CATEGORIES } from "../context/CampaignContext/static";

export interface Especialidade {
  Codigo: number,
  Nome: string,
}

export class AxiosRequestEspecialidade {
  async get (): Promise<Especialidade[]> {
    const response = await axios.get(API_LIST_CATEGORIES);
    return response.data.ObjetoRetorno.map((o:any) => o as Especialidade);
  }
}