import { Container, Row } from 'react-bootstrap';

import * as S from './style';
import { AxiosRequestEspecialidade, Especialidade } from '../../domain/AxiosRequestEspecialidade';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AxiosRequestParceiros, Parceiro } from '../../domain/AxiosRequestParceiros';
import { AxiosRequestUF } from '../../domain/AxiosRequestUF';
import { AxiosRequestCidade } from '../../domain/AxiosRequestCidade';
import useAjvValidationWithCtx from '../../hooks/useAjvValidationWithCtx';
import { SearchFormContext } from '../../context/SearchFormDataContext';
import CustomSelect from '../CustomSelect';

export interface ResponseDTO {
    setParceiros: (parceiros: Parceiro[]) => void
}

export default function Form({ setParceiros }: ResponseDTO){
    const [especialidades, setEspecialidades] = useState<Especialidade[]>([]);
    const [especialidade, setEspecialidade] = useState<Especialidade>();
    const [ufs, setUFs] = useState<string[]>();
    const [cidades, setCidades] = useState<string[]>();
    const { formData: { userInputs }} = useContext(SearchFormContext);
    const { checkIsValid } = useAjvValidationWithCtx(SearchFormContext, "search");

    const handleSubmit = useCallback(async (evt: any) => {
        const requestParceiros = new AxiosRequestParceiros();
        evt.preventDefault();
        checkIsValid();
        if (especialidade) {
            const request = await requestParceiros.get(especialidade, userInputs.uf, userInputs.cidade);
            setParceiros(request)
        }
    }, [checkIsValid, especialidade, setParceiros, userInputs.cidade, userInputs.uf]);

    const loadEspecialidades = useCallback(async () => {
        const request = new AxiosRequestEspecialidade();
        const especialidades = await request.get();
        setEspecialidades(especialidades);
    }, [])

    const loadUF = useCallback(async () => {
        const requestUf = new AxiosRequestUF();
        const selectedEspecialidade = especialidades.find((e) => e.Nome === userInputs.especialidade);
        setEspecialidade(selectedEspecialidade);
        if (selectedEspecialidade) {
            const ufs = await requestUf.get(selectedEspecialidade);
            setUFs(ufs)
        }
    }, [especialidades, userInputs.especialidade])

    const loadCidades = useCallback(async () => {
        const requestCidade = new AxiosRequestCidade();
        if (especialidade) {
            const cidades = await requestCidade.get(especialidade, userInputs.uf);
            setCidades(cidades)
        }
    }, [especialidade, userInputs.uf])

    useEffect(() => {
        if (especialidades?.length <= 0) {
            loadEspecialidades();
        } 
        if (userInputs.especialidade) {
            loadUF();
        }
        if (userInputs.uf) {
            loadCidades();
        }
    },[especialidades?.length, loadCidades, loadEspecialidades, loadUF, userInputs.especialidade, userInputs.uf]);
    
    return<>
        <S.Form>
            <Container>
                <Row>
                    <form action="" onSubmit={handleSubmit}>
                        <CustomSelect schemaName="search" ctx={SearchFormContext} name="especialidade">
                            <option value="" disabled hidden>
                                Selecione o assunto
                            </option>
                            {especialidades.map(e => <option key={e.Nome} value={e.Nome}>{e.Nome}</option>)}        
                        </CustomSelect>
                        <CustomSelect schemaName="search" ctx={SearchFormContext} name="uf">
                            <option value="" disabled hidden>
                                UF
                            </option>
                            {ufs?.map(uf => <option value={uf}>{uf}</option>)}
                        </CustomSelect>
                        <CustomSelect schemaName="search" ctx={SearchFormContext} name="cidade">
                            <option value="" disabled hidden>
                                Selecione a cidade
                            </option>
                            {cidades?.map(cidade => <option value={cidade}>{cidade}</option>)}
                        </CustomSelect>
                        <button>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24.37" height="24.282" viewBox="0 0 24.37 24.282"><path id="Shape" d="M30.17,28.674l-6.981-6.961A9.777,9.777,0,1,0,21.82,23.1L28.8,30.082ZM9.951,21.067A7.958,7.958,0,0,1,21.194,9.824,7.958,7.958,0,0,1,9.951,21.067Z" transform="translate(-5.8 -5.8)"></path></svg>
                        </button>
                    </form>
                </Row>
            </Container>
        </S.Form>
    </>
}