import { ReactElement } from "react"
import { Col, Container, Row } from 'react-bootstrap'
import { useCampaign } from "../../../context/CampaignContext"
import { DefaultButton } from "../../DefaultButton"
import * as S from './styled'

export function MensalidadeSection (): ReactElement {
  const { campaignData } = useCampaign()
  return <S.Mensalidade>
      <Container>
              <picture>
                  <source srcSet={campaignData.imgs.preco.bgMobile} media="(max-width: 48em)" />
                  <source srcSet={campaignData.imgs.preco.bgDesktop} />
                  <img src={campaignData.imgs.preco.bgDesktop} alt="banner torcida" />
              </picture>
          <Row>
              <Col md={6}>
                  <h1>Cartão de TODOS<br />{campaignData.teamName}</h1>
                  <p>Com o Cartão de TODOS, sua família passa por consultas, dentistas e faz exames na hora que precisa, pelo preço que pode pagar. E você ainda tem acesso a descontos em educação e lazer.</p>
                  <Row>
                      <fieldset tabIndex={-1} aria-disabled={true} className="preco centro">
                          <legend><span>Mensalidade de apenas</span></legend>
                          <p>R$<span className='preco-g'>29</span><span className='preco-centavo'>,70</span></p>
                          <h3>PARA TODA A FAMÍLIA</h3>
                      </fieldset>
                      <fieldset tabIndex={-1} aria-disabled={true} className='preco'>
                          <legend><span>Consultas</span></legend>
                          <div className='consultas'>
                              <h3>Clínico<br />geral</h3>
                              <p><span className="currency-unit">R$</span><span>26</span></p>
                          </div>
                          <div className='consultas'>
                              <h3>Demais<br />áreas</h3>
                              <p><span className="currency-unit">R$</span><span>35</span></p>
                          </div>
                      </fieldset>
                  </Row>
                  <div className="disclaimer">Anuidade de R$ 30 reais, pode ser parcelada em até 12 vezes no cartão. E você ganha sua carteirinha personalizada, saúde, educação e lazer!</div>
                  <a className="subBt" href={campaignData.salesUrl}><DefaultButton>solicitar meu cartão</DefaultButton></a>
                  <picture>
                      <source srcSet={campaignData.imgs.preco.cartaoPreco} />
                      <img src={campaignData.imgs.preco.cartaoPreco} alt="cartao de todos" />
                  </picture>
              </Col>
          </Row>
      </Container>
    </S.Mensalidade>
}