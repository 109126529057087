import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react";

export type ISearchUserInputs = {
  especialidade: string;
  uf: string;
  cidade: string;
};

export type ISearchFormData = { userInputs: ISearchUserInputs } & {
  errors: { [key in keyof ISearchUserInputs]: string };
};

export type ISearchFormCtx = {
  updateFormData: Dispatch<SetStateAction<ISearchFormData>>,
  formData: ISearchFormData
};

type ISearchFormProvider = ({ children }: { children: ReactNode }) => JSX.Element;

export const INITIAL_FORM_DATA = {
  userInputs: {
    especialidade: '',
    uf: '',
    cidade: '',
  },
  errors: {
    especialidade: '',
    uf: '',
    cidade: '',
  },
};

const INITIAL_FORM_CTX: ISearchFormCtx = {
  updateFormData: (() =>
    console.log("Context not found")) as unknown as Dispatch<
    SetStateAction<ISearchFormData>
  >,
  formData: INITIAL_FORM_DATA,
};

export const SearchFormContext = createContext(INITIAL_FORM_CTX);

const { Provider: CtxProvider } = SearchFormContext;


export const SearchFormProvider: ISearchFormProvider = ({ children }) => {
  const [formData, updateFormData] = useState<ISearchFormData>(INITIAL_FORM_DATA);
  const formContext = {
    formData,
    updateFormData,
  };

  return <CtxProvider value={formContext}>{children}</CtxProvider>;
};
