import axios from 'axios'
import { BadRequestError } from '../../main/errors/BadRequestError'

export class AxiosAdapter {
  async get (url, params) {
    try {
      const response = await axios.get(url, params)
      return response.data
    } catch (error) {
      if (axios.response) {
        if (error.response.status >= 400 && error.response.status <= 499) {
            throw new BadRequestError(error)
        }
      }
    }
  }
}