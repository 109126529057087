import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Parceiros from "./pages/Parceiros";
import Beneficios from "./pages/Beneficios";
import Contato from "./pages/Contato";
import { ContactFormProvider } from "./context/ContactFormDataContext";
import CampaignProvider from "./context/CampaignContext";
import "./App.css";
import { SearchFormProvider } from "./context/SearchFormDataContext";

function App() {
    return (
        <CampaignProvider>
            <SearchFormProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/parceiros" element={<Parceiros />} />
                        <Route path="/beneficios" element={<Beneficios />} />
                        <Route
                            path="/contato"
                            element={
                                <ContactFormProvider>
                                    <Contato />
                                </ContactFormProvider>
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </SearchFormProvider>
        </CampaignProvider>
    );
}

export default App;
