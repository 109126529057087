import React, {
  useContext,
  SelectHTMLAttributes,
  ChangeEventHandler,
  Context,
} from "react";
import useValidInputCtx from "../../hooks/useValidInputCtx";
import * as S from "./style";

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  schemaName: string;
}

function CustomSelect<K extends Context<any>>({ name, ctx, schemaName, children, ...customParam }: SelectProps & { ctx: K }) {
  const { formData } = useContext(ctx);
  const {
    inputParams: { onChange, onFocus, onBlur },
    error,
  } = useValidInputCtx(ctx, schemaName, name);

  return (
    <S.InputContainer>
      <S.Select
        className={error ? "input-error" : ""}
        name={name}
        onChange={onChange as unknown as ChangeEventHandler}
        onFocus={onFocus as unknown as ChangeEventHandler}
        onBlur={onBlur as unknown as ChangeEventHandler}
        value={formData.userInputs[name]}
        {...customParam}
      >
        {children}
      </S.Select>
      {error && <span className="error-icon">!</span>}
    </S.InputContainer>
  );
}

export default CustomSelect;
