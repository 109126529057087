// interface Params {
//   team: number
//   season: number
//   next: number
// }

// interface Response {
//   get: string,
//   parameters: {
//       team: string,
//       next: string,
//       season: string
//   },
//   errors: any[],
//   results: number,
//   paging: {
//       current: number,
//       total: number
//   },
//   response: any[]
// }

export class NextGame {
  findNextGameRepository = null

  constructor (findNextGameRepository) {
    this.findNextGameRepository = findNextGameRepository
  }
  
  async handle (params) {
    if (!params.teamId) throw new Error('TeamId is not present')
    const response = await this.findNextGameRepository.find(params)
    return {
      image: response.logo,
      title: response.name
    }
  }
}
