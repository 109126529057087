import * as S from "./style";
import nextMatchsJSON from "../../assets/json/next-matchs.json";
import React, { useCallback, useEffect, useState } from "react";
import { useCampaign } from "../../context/CampaignContext";
import { makeNextGameUseCase } from "../../team-game/main/helper/make-next-game-use-case";

const nextGame = makeNextGameUseCase()

interface INextMatch {
  name: string;
  logo: string;
}

function NextMatch(): React.ReactElement {
  const [nextMatch, setNextMatch] = useState<INextMatch|null>();
  const { campaignData } = useCampaign();

  const loadNextMatch = useCallback(async () => {
    try {
      const result = await nextGame.handle({ teamId: 753 })

      setNextMatch({
        name: result.title,
        logo: result.image,
      })
    } catch (error) {
      setNextMatch(null)
    }
  }, [])

  useEffect(() => {
    loadNextMatch()
  }, [loadNextMatch]);

  return (
    <S.NextMatchContainer>
      <S.NextMatchTitle>Próximo Jogo</S.NextMatchTitle>
      <S.Players>
        <S.NextMatchPlayer>
          <img src={campaignData.teamShield} alt="escudo-casa" className="home" />
          <span>{campaignData.teamName}</span>
        </S.NextMatchPlayer>
        <span>X</span>
        {nextMatch ? (
          <>
            <S.NextMatchPlayer>
              <img src={nextMatch.logo} alt="escudo-opposing-team" />
              <span>{nextMatch.name}</span>
            </S.NextMatchPlayer>
          </>
        ) : (
          <S.NextMatchPlayer>Aguardando partida</S.NextMatchPlayer>
        )}
      </S.Players>
    </S.NextMatchContainer>
  );
}

export default NextMatch;
